<template>
  <div class="page-wallet">
    <b-row>
      <b-col lg="4" sm="6" cols="12">
        <BoxElement
          title="Balance"
          :value="`$ ${TruncateToDecimals(InfoDashboard.MainBalance, 4)}`"
          :balance="InfoDashboard"
          action="transfer"
          class="balance-card"
        ></BoxElement>
      </b-col>
      <b-col lg="4" sm="6" cols="12">
        <BoxElement
          title="Total Deposit"
          :value="`$ ${TruncateToDecimals(InfoDashboard.TotalDeposit, 4)}`"
          action="deposit"
          class="balance-card"
        ></BoxElement>
      </b-col>
      <b-col lg="4" sm="6" cols="12">
        <BoxElement
          :balance="InfoDashboard"
          title="Total Withdraw"
          :value="`$ ${TruncateToDecimals(InfoDashboard.TotalWithdraw, 4)}`"
          action="withdraw"
          class="balance-card"
        ></BoxElement>
      </b-col>
    </b-row>
    <b-row class="p-0">
      <b-col class="12">
        <div class="card">
          <div class="card-header text-left py-3">
            <h3 class="mb-0 fz-md">Wallet History</h3>
          </div>
          <div class="card-body">
            <div class="table-reponsive">
              <b-table striped hover :items="Wallet.list" :fields="fields">
                <template #cell(Money_Time)="data">
                  <p class="mb-0">
                    {{ getDateTime(data.item.Money_Time) }}
                  </p>
                </template>
                <template #cell(Money_Rate)="data">
                  <p class="mb-0">
                    {{ TruncateToDecimals(data.item.Money_Rate, 4) }}
                  </p>
                </template>
                <template #cell(Money_USDT)="data">
                  <p class="mb-0">
                    {{ TruncateToDecimals(data.item.Money_USDT, 4) }}
                  </p>
                </template>
                <template #cell(Money_USDTFee)="data">
                  <p class="mb-0">
                    {{ TruncateToDecimals(data.item.Money_USDTFee, 4) }}
                  </p>
                </template>
                <template #cell(Money_Currency)="data">
                  <p class="mb-0">
                    {{
                      data.item.Money_MoneyAction === 2
                        ? getSymbol(data.item.Money_CurrencyTo)
                        : getSymbol(data.item.Money_Currency)
                    }}
                  </p>
                </template>
                <template #cell(Money_MoneyAction)="data">
                  <p class=" mb-0">
                    {{ getNameAction(data.item.Money_MoneyAction) }}
                  </p>
                </template>
                <template #cell(Money_MoneyStatus)="data">
                  <p
                    class="mb-0 font-weight-bold"
                    :class="
                      getClass(
                        data.item.Money_MoneyStatus,
                        data.item.Money_Confirm,
                        data.item.Money_MoneyAction,
                      ).class
                    "
                  >
                    {{
                      getClass(
                        data.item.Money_MoneyStatus,
                        data.item.Money_Confirm,
                        data.item.Money_MoneyAction,
                      ).text
                    }}
                  </p>
                </template>
              </b-table>
            </div>
            <div class="table-paginate">
              <Paginate @current="onChangePage" :totalPages="Wallet.total" />
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import filter from 'lodash/filter';

import moment from 'moment';
import BoxElement from '@/components/element/Box.vue';
import Paginate from '@/components/shared/Paginate.vue';

export default {
  components: { BoxElement, Paginate },
  data() {
    return {
      deposit: {
        coin: null,
      },
      currentPage: 1,
      total: 0,
      fields: [
        {
          key: 'Money_ID',
          sortable: false,
          label: 'Money ID',
        },
        {
          key: 'Money_Currency',
          sortable: false,
          label: 'Money Currency',
        },
        {
          key: 'Money_Comment',
          label: 'Money Comment',
          sortable: false,
        },
        {
          key: 'Money_MoneyAction',
          label: 'Money Action',
          sortable: false,
        },
        {
          key: 'Money_MoneyStatus',
          label: 'Money Status',
          sortable: false,
        },
        {
          key: 'Money_Rate',
          label: 'Money Rate',
          sortable: false,
        },
        {
          key: 'Money_Time',
          label: 'Money Time',
          sortable: false,
        },
        {
          key: 'Money_USDT',
          label: 'Money USDT',
          sortable: false,
        },
        {
          key: 'Money_USDTFee',
          label: 'Money USDT Fee',
          sortable: false,
        },
        {
          key: 'Money_User',
          label: 'Money User',
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      Coin: 'wallet/Coin',
      InfoDashboard: 'wallet/InfoDashboard',
      Wallet: 'history/Wallet',
      Actions: 'wallet/Actions',
    }),
  },
  methods: {
    onChangePage(payload) {
      this.currentPage = payload;
      this.$store.dispatch('history/req_getHistoryWallet', { page: this.currentPage });
    },
    getDateTime(unix) {
      return moment.unix(unix).utcOffset('+00:00').format('DD/MM/YYYY, HH:mm:ss');
    },
    getSymbol(id) {
      return filter(this.Coin.symbol, (elm) => elm.id === id)[0].Name;
    },
    getNameAction(id) {
      return filter(this.Actions, (elm) => elm.MoneyAction_ID === id)[0].MoneyAction_Name;
    },
    getClass(status, confirm, action) {
      // console.log(status, confirm, action);
      if (action === 2) {
        if (confirm === 1) {
          return {
            class: 'text-success',
            text: 'Confirmed',
          };
        }
        if (confirm === -1) {
          return {
            class: 'text-danger',
            text: 'Canceled',
          };
        }
        return {
          class: 'text-warning',
          text: 'Pending',
        };
      }
      if (status === 1) {
        return {
          class: 'text-success',
          text: 'Confirmed',
        };
      }
      if (status === -1) {
        return {
          class: 'text-danger',
          text: 'Canceled',
        };
      }
      return {
        class: 'text-warning',
        text: 'Pending',
      };
    },
  },
  created() {
    this.$store.dispatch('history/req_getHistoryWallet');
  },
};
</script>

<style lang="scss">
.page-wallet {
  .list-coin-seciton {
    width: 100%;
    min-height: 250px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .box-coin {
      flex: 0 0 33.3%;
      max-width: 33.3%;
      .coin {
        box-shadow: 0px 0px 10px 2px #00000038;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        padding: 15px 0;
        margin-bottom: 15px;
        margin: 0 auto 15px;
        position: relative;
        width: calc(100% - 20px);
        // min-width: 410px;

        .left {
          position: absolute;
          top: 10px;
          right: 10px;
          .icon {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 5px;
            width: 100%;
            border-radius: 50%;
            box-shadow: 0px 2px 10px 2px #0000006e;
            img {
              width: 100%;
              max-width: 40px;
              height: auto;
              filter: drop-shadow(0px 0px 10px 2px #00000050);
            }
          }
        }
        .right {
          flex: 1 1 auto;
          padding-left: 5px;
          .coin-name {
            font-weight: 600;
            font-size: 20px;
            color: #2f394e;
            text-align: left;
            padding-left: 10px;
          }
          .coin-info {
            padding-left: 10px;
            .title {
              font-weight: 600;
              color: #2f394e;
              opacity: 0.9;
              text-align: center;
              text-transform: uppercase;
              margin-right: 5px;
              font-size: 15px;
            }
            .value {
              font-weight: 600;
              font-size: 19px;
              color: #2f394e;
              text-align: center;
            }
          }
          .coin-action {
            margin-top: 15px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            flex-wrap: wrap;
            button {
              flex: 1;
              min-width: 90px;
              max-width: 120px;
              height: 40px;
              box-shadow: 0 2px 6px 0 #ccc;
              font-weight: 600;
              margin: 5px 5px;
              display: flex;
              justify-content: center;
              align-items: center;
              &:hover,
              &:focus,
              &:focus-visible {
                box-shadow: 0 5px 18px rgba(0, 0, 0, 0.2), 0 10px 12px rgba(0, 0, 0, 0.2);
              }
            }
          }
        }
      }
    }
    @media (max-width: 1600px) {
      .box-coin {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
    @media (max-width: 991px) {
      .box-coin {
        flex: 0 0 50%;
        max-width: 50%;
        .coin {
          width: calc(100% - 10px);
          max-width: 520px;
        }
      }
    }
    @media (max-width: 767px) {
      .box-coin {
        flex: 0 0 100%;
        max-width: 100%;
        .coin {
          width: 100%;
          max-width: 520px;
        }
      }
    }
  }
  .wallet-action {
    width: 100%;
    min-height: 250px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 7px 10px 2px #00000038;
    position: relative;
  }
  .history-box {
    box-shadow: 0px 0px 10px 2px #00000038;
    background-color: #fff;
    min-height: 250px;
    border-radius: 10px;
    padding: 15p;
    width: 100%;
    .title {
      font-weight: 600;
      font-size: 22px;
      color: #2f394e;
      text-align: left;
      padding: 10px 15px;
      border-bottom: 3px solid rgba($color: #000000, $alpha: 0.2);
      margin-bottom: 0;
      display: flex;
      align-items: center;
    }
  }
}
.table-reponsive {
  width: 100%;
  overflow-x: auto;
}
</style>
